<template>
  <div class="main">
    <div class="row px-4 py-4">
      <div v-for="branch in branchs" :key="branch._id" class="col-xs-12 pb-4">
        <div class="userCard mb-2 rounded card">
          <div class="userBranch py-2 px-3 d-flex justify-content-between">
            <h5 class="text-capitalize fw-bold">{{ branch.cityName }}</h5>
            <p class="text-end">{{ branch.description }}</p>
          </div>
          <div class="rowUser row">
            <div
              v-for="user in userList(branch._id)"
              :key="user._id"
              class="userContent mx-2 mb-3 col-sm-12 col-lg-2"
            >
              <div
                @click="editEmployee(user._id)"
                class="userPhoto mb-3 col-sm-12 col-lg-2"
              >
                <img
                  v-if="user.avatarURL"
                  :src="
                    user.avatarURL +
                      '?seed=' +
                      Math.floor(Math.random() * 10000000)
                  "
                  alt="userPhoto"
                />
                <img
                  v-else
                  src="https://storage.assoft.co/img/default/icon_black.svg"
                  alt="userPhoto"
                />
              </div>
              <h6 class="mt-2 mb-0 text-center">{{ user.name }}</h6>
              <p class="mb-0 text-center text-capitalize">{{ user.rol }}</p>
            </div>
            <div
              class="userContent mx-2 mb-3 col-sm-12 col-lg-2"
              v-if="!disableCreateUser"
            >
              <svg
                @click="newUser(branch._id)"
                class="bi text-center"
                fill="currentColor"
              >
                <use xlink:href="/img/icons/bootstrap-icons.svg#plus-circle" />
              </svg>
              <h6 class="mt-2 mb-0 text-center">Nuevo Usuario</h6>
              <p class="mb-0 text-center"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Legal></Legal>
  </div>
</template>

<script>
import Legal from "@/components/layout/Legal";
import Storage from "@/modules/storage";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      branchs: [],
      employees: [],
      users: [],
      disableCreateUser: false
    };
  },
  beforeCreate() {
    localStorage.setItem("currentLocation", "Usuarios");
  },
  async mounted() {
    const store = new Storage();
    const planData = await store.getData(
      "plan",
      localStorage.getItem("subscription.plan")
    );
    const employeeData = await store.getData("employee");
    const totalEmployee = employeeData.length;
    const limitEmployee = planData.restrictions.employees;

    if (totalEmployee >= limitEmployee) {
      this.disableCreateUser = true;
      Swal.fire({
        title: "No es posible registrar nuevos usuarios",
        icon: "warning",
        text:
          "La susbscripción que posee tiene un límite de " +
          limitEmployee +
          " empleados, actualmente están registrados " +
          totalEmployee +
          ". Para registrar nuevos usuarios por favor cambie de plan.",
        showCancelButton: true,
        confirmButtonText: `Cambiar Plan`,
        cancelButtonText: "Continuar",
        cancelButtonColor: "#6c757d"
      }).then(result => {
        if (result.isConfirmed) {
          this.$router.push({ name: "Subscription" });
        }
      });
    }

    store.getData("employee").then(employees => {
      this.employees = employees;
    });
    store.getData("user").then(users => {
      this.users = users;
    });
    store.getData("branch").then(data => {
      data.forEach(branch => {
        if (branch.bannerRoute == null) {
          branch.bannerRoute = "img/default/branch.jpg";
        }
        branch.bannerRoute = store.staticURL + "/" + branch.bannerRoute;
      });
      this.branchs = data;
      data.forEach((branch, index) => {
        store.getData("state", branch.state).then(state => {
          this.branchs[index].stateName = state.name;
        });
      });
      data.forEach((branch, index) => {
        store.getData("city", branch.city).then(city => {
          this.branchs[index].cityName = city.name;
        });
      });
    });
  },
  methods: {
    newUser(branchID) {
      this.$router.push({
        name: "User Create",
        params: {
          _id: branchID
        }
      });
    },
    userList(branchID) {
      const employees = [];
      this.users.forEach(user => {
        this.employees.forEach(employee => {
          if (employee.branch === branchID && user.employee === employee._id) {
            employee.rol = user.rol;
            employees.push(employee);
          }
        });
      });
      return employees;
    },
    editEmployee(ID) {
      this.$router.push({
        name: "Employee Profile Edit",
        params: {
          _id: ID
        }
      });
    }
  },
  components: { Legal },
  name: "User"
};
</script>

<style lang="css" scoped>
.userCard {
  background-color: var(--secondary-bg);
}
.userCard svg {
  cursor: pointer;
  width: 90px;
  height: 90px;
  color: var(--bs-primary);
  margin-left: 30px;
}
.userPhoto {
  cursor: pointer;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  overflow: hidden;
  margin: auto;
}
.userPhoto img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.userContent {
  width: 180px;
}
.rowUser {
  margin: auto;
  max-width: 97%;
  width: 1400px;
}
.userBranch p,
.userBranch h5 {
  max-width: 50%;
}
@media only screen and (max-width: 576px) {
  .rowUser {
    justify-content: center;
  }
  .userBranch {
    flex-direction: column;
  }
  .userBranch p,
  .userBranch h5 {
    max-width: unset;
    text-align: center !important;
  }
}
</style>
